module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"alert alert-danger alert-dismissible fade show\" role=\"alert\">\n                            "
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"User:error.incorrect_username_or_password",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":22,"column":28},"end":{"line":22,"column":78}}}))
    + "\n                            <button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">\n                                <span aria-hidden=\"true\">&times;</span>\n                            </button>\n                        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <hr class=\"my-3\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"bootquery") : depth0)) != null ? lookupProperty(stack1,"authProviders") : stack1),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":39,"column":33}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"row\">\n                            <div class=\"col-12 col-md-8 mx-auto mb-2\">\n                                <a href=\"/identity/login/"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"n")),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":65},"end":{"line":34,"column":121}}})) != null ? stack1 : "")
    + "\" class=\"btn btn-info btn-block noparse auth-provider-link\">\n                                    "
    + alias1(lookupProperty(helpers,"tr").call(alias2,"User:button.login_with",{"name":"tr","hash":{"provider":(depth0 != null ? lookupProperty(depth0,"displayName") : depth0)},"data":data,"loc":{"start":{"line":35,"column":36},"end":{"line":35,"column":88}}}))
    + "\n                                </a>\n                            </div>\n                        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "?n="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"n")), depth0));
},"9":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"login-screen-bottom-logo col-12 mx-auto text-center my-3\">\n                <img src=\"/app/assets/img/clickvox_logo.svg\" alt=\"ClickVOX Logo\">\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"head"),depth0,{"name":"head","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<div id=\"content-wrapper\" class=\"login-screen bg-light\">\n	<div class=\"container nomenu mx-auto mx-auto\" id=\"main-container\">\n		<div id=\"php-debugs\">"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"_php_debug") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":23},"end":{"line":4,"column":62}}})) != null ? stack1 : "")
    + "</div>\n        <div id=\"react-widgets-root\"></div>\n\n		<div class=\"bq-content bq-default mx-auto login-screen-content\">\n            <div class=\"login-screen-logo mt-5 mb-3 col-10 col-md-8 col-lg-6 mx-auto\">\n                <img src=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"logoPath")), depth0))
    + "\" alt=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"appTitle")), depth0))
    + " Logo\" style=\"width: 100%; height: auto;\">\n            </div>\n\n			<div class=\"col-12 login-screen-main\">\n                <div class=\"card\">\n                    <div class=\"card-body\">\n                        <h3 class=\"card-title font-weight-bold text-center mb-4\">\n                            <span class=\"fa fa-sign-in-alt\"></span>\n                            "
    + alias3(lookupProperty(helpers,"tr").call(alias1,"User:label.login",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":17,"column":28},"end":{"line":17,"column":53}}}))
    + "\n                        </h3>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"failed")),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":27,"column":31}}})) != null ? stack1 : "")
    + "                        "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"loginForm"),depth0,{"name":"loginForm","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"bootquery") : depth0)) != null ? lookupProperty(stack1,"authProviders") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":24},"end":{"line":40,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"whiteLabel")),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":12},"end":{"line":49,"column":23}}})) != null ? stack1 : "")
    + "		</div>\n	</div>\n</div>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"end"),depth0,{"name":"end","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true})