import { ReactElement, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useReactRoutes } from '../js/react-routes';
import { LoadingPage } from './LoadingPage';
import { RouteErrorWrapper } from './RouteErrorWrapper';

export const PageContent = (): ReactElement => {
  const routes = useReactRoutes();

  return (
    <>
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          {/* This layout route catches any thrown errors and shows an error message */}
          <Route element={<RouteErrorWrapper />}>
            {routes.map((provider) => (
              <Route
                key={provider.path}
                element={<provider.component />}
                path={provider.path}
              />
            ))}
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};
