import { forEach, get, lowerFirst } from 'lodash-es';

import { setFormSaveStatus } from 'BootQuery/Assets/js/BootQuery';
import Module from 'BootQuery/Assets/js/module';
import { settingsEvents } from 'BootQuery/Assets/js/settings-events';

import { BrandingSettings } from '../components/BrandingSettings';

export default class Settings extends Module {
  get provides() {
    return {
      reactWidgets: {
        brandingSettings: {
          target: '#branding-settings-root',
          component: BrandingSettings,
        },
      },
    };
  }

  activateElements(target, data) {
    const tabs = get(data, 'result.tabs');
    if (!tabs) {
      return;
    }

    const tabHash = window.location.hash;
    if (tabHash) {
      const tab = target.findElement(`a[href="${tabHash}"]`);
      if (tab.length) {
        tab.siblings('a').removeClass('active show');
        tab.addClass('active show');
        const tabContent = target.find(tabHash);
        tabContent.siblings().removeClass('show active');
        tabContent.addClass('show active');
      }
    }

    target
      .findElement('#userSettings-form')
      .off('submitted.settings')
      .on('submitted.settings', (e, data) => {
        const $form = $('#userSettings-form');
        const errors = get(data, 'result.errors');
        const changed = get(data, 'result.changed');

        if (changed) {
          $(document).trigger('userSettingsChanged', changed);
        }
        if (errors && Object.entries(errors).length) {
          setFormSaveStatus($form, 'validation-error');
          $(document).trigger('onUserSettingsErrors', errors);
        }
      });

    target
      .findElement('#settings-form')
      .off('submitted.settings')
      .on('submitted.settings', (e, data) => {
        const changed = get(data, 'result.changed');
        if (changed) {
          $(document).trigger('settingsChanged', changed);
          settingsEvents.emit('settingsChanged', changed);
        }
      });

    forEach(tabs, (tab) => {
      if (!get(tab, 'data.forms')) {
        return;
      }
      forEach(tab.data.forms, (form, formName) => {
        let $settingsEl = null;
        if (form.elementSelector) {
          $settingsEl = target.findElement(form.elementSelector);
        } else {
          $settingsEl = target.findElement(`.tab-pane#setting-${tab.key}`);
        }
        $settingsEl.form({ formDefinition: form });
      });
    });

    target.findElement('a[data-toggle="list"]').on('shown.bs.tab', (e) => {
      const hash = $(e.target).attr('href').substr(1);

      const historyObj = {
        controller: lowerFirst(window.Bootstrap.bootquery.controller),
        method: lowerFirst(window.Bootstrap.bootquery.method),
        hash,
      };

      let newUrl;
      if (window.window.Bootstrap.bootquery.controller) {
        newUrl = `/${window.Bootstrap.bootquery.controller}/${window.Bootstrap.bootquery.method}/`;
      }

      if (hash) {
        newUrl += `#${hash}`;
      }

      window.history.replaceState(historyObj, null, newUrl);
    });
  }
}
