import { useMemo } from 'react';

export interface ReactWidgetDefinition {
  component: () => JSX.Element;
  target: '$portal' | string | (() => HTMLElement | null);
  condition?: (() => boolean) | null;
}

export interface NamedReactWidgetDefinition extends ReactWidgetDefinition {
  name: string;
}

export type ReactWidgetProvider = Record<string, ReactWidgetDefinition>;

export function getReactWidgetProviders(): ReactWidgetProvider[] {
  return window.BootQuery.getProviders<ReactWidgetProvider>('reactWidgets');
}

export function getReactWidgets(): NamedReactWidgetDefinition[] {
  const providers = getReactWidgetProviders();

  return providers
    .map((provider) => {
      return Object.entries(provider).map(([name, widget]) => ({
        name,
        ...widget,
      }));
    })
    .flat();
}

export function useReactWidgets(): NamedReactWidgetDefinition[] {
  return useMemo(getReactWidgets, []);
}
