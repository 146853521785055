import { ReactElement, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { LoadingPage } from '@bq/components/LoadingPage';
import { useSettingsCrud } from '@bq/components/SettingsCRUD';
import { useModal } from '@bq/components/UseModal';

import { mailsModule } from '../../js/mails';
import { TemplateListItem } from './TemplateListItem';
import { TemplateModal } from './TemplateModal';
import { MailTemplate } from './types';

export const TemplateSettings = (): ReactElement => {
  const { data: templates } = useQuery({
    queryKey: ['Mails.templates'],
    queryFn: () => mailsModule().getTemplates(),
  });

  if (!templates) {
    return <LoadingPage />;
  }

  return <TemplateSettingsContent templates={templates} />;
};

interface ContentProps {
  templates: MailTemplate[];
}

const TemplateSettingsContent = ({
  templates: originalTemplates,
}: ContentProps): ReactElement => {
  const { t } = useTranslation('Mails');
  const { addModal } = useModal();

  const [templates, modify] = useSettingsCrud<MailTemplate>({
    parent: '#settings-form',
    inputName: 'mails[templates]',
    defaultValue: { add: [], upd: {}, del: [] },
    items: originalTemplates,
  });

  const visibleTemplates = templates.filter(
    (template) => template.change !== 'del'
  );

  const addTemplate = useCallback(() => {
    addModal({
      children: <TemplateModal mode="create" />,
      preventClose: {
        defaultPreventState: false,
      },
      componentProps: {
        modal: { size: '6xl', scrollBehavior: 'inside' },
      },
      callback: modify.add,
    });
  }, [addModal, modify.add]);

  return (
    <>
      <h4>{t('Mails:templates')}</h4>
      <ul className="list-group">
        {visibleTemplates.map((template) => (
          <TemplateListItem
            key={template.ID}
            template={template}
            modify={modify}
          />
        ))}
      </ul>
      <button
        type="button"
        className="btn btn-success float-right my-3"
        onClick={addTemplate}
      >
        <span className="fa fa-plus"></span> {t('Mails:add_template')}
      </button>
    </>
  );
};
