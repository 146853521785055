import { FaLink, FaListAlt, FaPlus } from 'react-icons/fa';

import SoundNotification from 'app/assets/sounds/long.mp3';
import {
  awaitCall,
  dial,
} from 'app/Modules/Telephony/Assets/components/Dialer/store/dialer';
import * as Api from 'BootQuery/Assets/js/apiRequest';
import { renderController } from 'BootQuery/Assets/js/BootQuery';
import Module from 'BootQuery/Assets/js/module';
import tr from 'BootQuery/Assets/js/translate';

import { ticketEventProviders } from '../components/EventTypes';
import { PermissionSettings } from '../components/PermissionSettings';
import { ProvidedReports } from '../components/Reports';
import { Settings } from '../components/Settings';
import { TicketPickerConnector } from '../components/TicketPickerModal';
import { pickTicket } from './pick-ticket';
import { ReactRoutes } from './react-routes';
import { ticketingEventBus } from './ticketing-event-bus';

async function openAssignCallToTicket(callID) {
  const createTicket = async (ticket) => {
    await Api.post(`/api/ticketing/tickets/${ticket.ID}/calls`, callID);
  };

  pickTicket(createTicket, { title: 'Pridruzi poziv ticketu' });
}

export default class Ticketing extends Module {
  static matchReactRoute = '/ticketing/';

  init() {
    super.init();
    this.notificationSound = new Audio(SoundNotification);

    this.getTicketTypes();
    this.getTicketStates();
    this.getTicketGroups();

    this.loadedAt = null;
    this.userInfoCache = {};
    this.selectedGroup = null;
  }

  get provides() {
    return {
      reactRoutes: {
        ReactRoutes,
      },
      reports: ProvidedReports,
      callActions: {
        callActions(callInfo) {
          const point =
            callInfo.direction === 'outgoing'
              ? callInfo.destinationInfo?.destination ?? null
              : callInfo.sourceInfo?.source ?? null;

          const createParams = [`fromCallID=${callInfo.ID}`];
          const contact =
            point?.type === 'phonePoint' ? point.point.contact : null;
          if (contact && ['person', 'company'].includes(contact.type)) {
            createParams.push(`defaultClientID=${contact.type}_${contact.ID}`);
          }

          return [
            {
              type: 'group',
              text: 'Ticketing',
              icon: FaListAlt,
              actions: [
                {
                  type: 'link',
                  text: tr('Ticketing:menu_entries.create_ticket'),
                  icon: FaPlus,
                  href: `/ticketing/create/?${createParams.join('&')}`,
                },
                {
                  type: 'action',
                  text: tr('Ticketing:menu_entries.add_to_ticket'),
                  icon: FaLink,
                  onClick: (call) => {
                    openAssignCallToTicket(callInfo.ID);
                  },
                },
              ],
            },
          ];
        },
        priority: 20,
      },
      eventTypes: ticketEventProviders,
      reactWidgets: {
        ticketingSettings: {
          target: '#ticketing-settings-root',
          component: Settings,
        },
        ticketingPermissionSettings: {
          target: '#ticketing-permissions-react-root',
          component: PermissionSettings,
        },
        ticketPicker: {
          target: '$portal',
          component: TicketPickerConnector,
        },
      },
    };
  }

  refresh() {
    const route = { ...window.Bootstrap.bootquery };
    renderController(
      'get',
      route.controller,
      route.method,
      route.parameters,
      route.moduleName
    );
  }

  activateElements(target, data) {
    $('body').ev('click.ticketing', '.clickvox-mail-btn', (e) => {
      if (!this.getTicketID()) {
        return; // Don't do anything if outside ticket
      }

      e.preventDefault();
      e.stopPropagation();

      ticketingEventBus.emit('tab/switch', 'events');

      const $el = $(e.currentTarget);
      const address = $el.data('mailAddress');

      const $popover = $el.closest('.popover');
      if ($popover.length) {
        $popover.popover('hide');
      }

      ticketingEventBus.emit('mail/newEditor', { to: [address] });
    });

    this.loadedAt = new Date();
  }

  async getUserInfo(userID) {
    if (!this.userInfoCache[userID]) {
      this.userInfoCache[userID] = await Api.get(`/api/users/${userID}`);
    }
    return this.userInfoCache[userID];
  }

  async dial(number) {
    const dialResp = await dial(number);
    const call = await awaitCall(dialResp);
    this.addCall(call);
  }

  addEvent(event) {
    const ticketID = this.getTicketID();
    if (!ticketID) {
      this.addEventToInput(event);
    } else {
      switch (event.type) {
        case 'call':
          Api.post(`/api/ticketing/tickets/${ticketID}/calls`, event.callID);
          break;
        case 'mail':
          Api.post(`/api/ticketing/tickets/${ticketID}/mails`, event.mailID);
          break;
        default:
          break;
      }
    }
  }

  moveCallEvent(callID) {
    const ticketID = this.getTicketID();
    if (!ticketID) {
      console.warn('Not moving call event, no current ticket ID', ticketID);
      return;
    }

    pickTicket(
      async (selected) => {
        await Api.post(`/api/ticketing/tickets/${selected.ID}/calls`, callID);
        this.removeCallEvent(callID);
      },
      {
        title: tr('label.move_to_another_ticket', 'Ticketing'),
        filters: {
          'ID:neq': this.getTicketID(),
        },
      }
    );
  }

  async removeCallEvent(callID) {
    const ticketID = this.getTicketID();

    if (ticketID) {
      await Api.delete(`/api/ticketing/tickets/${ticketID}/calls/${callID}`);
    }

    ticketingEventBus.emit('call/remove', callID);
  }

  moveChatEvent(chatID) {
    const ticketID = this.getTicketID();
    if (!ticketID) {
      console.warn('Not moving chat event, no current ticket ID', ticketID);
      return;
    }

    pickTicket(
      async (selected) => {
        await Api.post(
          `/api/ticketing/tickets/${selected.ID}/customerChats`,
          chatID
        );
        this.removeChatEvent(chatID);
      },
      {
        title: tr('label.move_to_another_ticket', 'Ticketing'),
        filters: {
          'ID:neq': this.getTicketID(),
        },
      }
    );
  }

  async removeChatEvent(chatID) {
    const ticketID = this.getTicketID();

    if (ticketID) {
      await Api.delete(
        `/api/ticketing/tickets/${ticketID}/customerChats/${chatID}`
      );
    }

    ticketingEventBus.emit('chat/remove', chatID);
  }

  addEventToInput(event) {
    this.setEventsInput((prev) => ({
      ...prev,
      $add: [...prev.$add, event],
    }));
  }

  setEventsInput(updater) {
    const $container = $('#events');
    let $input = $container.find('input[name=eventChanges]');
    if (!$input.length) {
      $input = $('<input/>', {
        type: 'hidden',
        name: 'eventChanges',
      });
      $container.append($input);
    }

    const inputVal = $input.val();
    const newVal = inputVal.length
      ? updater(JSON.parse(inputVal))
      : updater({ $add: [], $remove: [] });

    $input.val(JSON.stringify(newVal));
  }

  getTicketID() {
    return this.ticketInfo?.ID ?? null;
  }

  getTicketTypes() {
    if (this.ticketTypes) {
      return Promise.resolve(this.ticketTypes);
    }
    if (this.typesPromise) {
      return this.typesPromise;
    }
    this.typesPromise = Api.get('/api/ticketing/types');
    this.typesPromise.then((types) => {
      this.ticketTypes = types;
      this.typesPromise = null;
      return this.ticketTypes;
    });
    return this.typesPromise;
  }

  getTicketStates() {
    if (this.ticketStates) {
      return Promise.resolve(this.ticketStates);
    }
    if (this.statesPromise) {
      return this.statesPromise;
    }
    this.statesPromise = Api.get('/api/ticketing/states');
    this.statesPromise.then((states) => {
      this.ticketStates = states;
      this.statesPromise = null;
      return this.ticketStates;
    });
    return this.statesPromise;
  }

  getTicketGroups() {
    if (this.groups) {
      return Promise.resolve(this.groups);
    }
    if (this.groupsPromise) {
      return this.groupsPromise;
    }
    this.groupsPromise = Api.get('/api/ticketing/groups').then(
      ({ data: groups }) => {
        this.groups = groups;
        this.groupsPromise = null;
        return this.groups;
      }
    );

    return this.groupsPromise;
  }
}
