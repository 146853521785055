import mitt from 'mitt';

import { createUseMitt } from './use-mitt';

export type GlobalEvents = {
  activateElements: {
    target: string;
    data: unknown;
  };
};

export const globalEventBus = mitt<GlobalEvents>();

export const useGlobalEvent = createUseMitt(globalEventBus);
