import Module from 'BootQuery/Assets/js/module';

import { PermissionSettings } from '../components/PermissionSettings';
import { Settings } from '../components/Settings';
import { ReactRoutes } from './react-routes';

export default class Sms extends Module {
  get provides() {
    return {
      reactRoutes: {
        ReactRoutes,
      },
      reactWidgets: {
        smsSettings: {
          target: '#sms-settings-root',
          component: Settings,
        },
        smsPermissionsSettings: {
          target: '#sms-permissions-react-root',
          component: PermissionSettings,
        },
      },
    };
  }

  static matchReactRoute = '/sms/';
}
