import Module from 'BootQuery/Assets/js/module';
import { lazy } from 'react';

const TimeRules = lazy(
  () => import('../components/TimeRules').then(({ TimeRules }) => ({ default: TimeRules }))
);

export default class Settings extends Module {
  get provides() {
    return {
      reactWidgets: {
        timeRuleSettings: {
          target: '#time-rule-settings',
          component: TimeRules,
        },
      },
    };
  }

  setRuleGetter(getRules) {
    $('#settings-form').ev('beforeSubmit.timeRules', (e) => {
      let input = $(e.currentTarget).find('[name="timeRules"]');
      if (!input.length) {
        input = $('<input/>', {
          type: 'hidden',
          name: 'timeRules',
        });
      }
      input.val(JSON.stringify(getRules()));
      $('#setting-timeRules').append(input);
    });
  }

  unsetRuleGetter() {
    $('#settings-form').off('beforeSubmit.timeRules');
  }
}
