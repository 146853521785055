import SoundNotification from 'app/assets/sounds/long.mp3';
import * as Api from 'BootQuery/Assets/js/apiRequest';
import { renderController } from 'BootQuery/Assets/js/BootQuery';
import Module from 'BootQuery/Assets/js/module';
import tr from 'BootQuery/Assets/js/translate';

import { offerEventBus } from '../components/Cases/Form/Events/case-event-bus';
import { CasePickerConnector } from '../components/Cases/Form/Events/CasePickerModal';
import { Settings } from '../components/Settings';
import { pickCase } from './pick-case';
import { ReactRoutes } from './react-routes';

export default class Sales extends Module {
  static matchReactRoute = '/sales/';

  init() {
    super.init();
    this.notificationSound = new Audio(SoundNotification);

    this.submitting = false;
    this.loadedAt = null;
    this.userInfoCache = {};
    this.selectedGroup = null;
  }

  get provides() {
    return {
      reactRoutes: {
        ReactRoutes,
      },
      reactWidgets: {
        salesSettings: {
          target: '#sales-settings-root',
          component: Settings,
        },
        salesCasePickerConnector: {
          target: '$portal',
          component: CasePickerConnector,
        },
      },
    };
  }

  refresh() {
    const route = { ...window.Bootstrap.bootquery };
    renderController(
      'get',
      route.controller,
      route.method,
      route.parameters,
      route.moduleName
    );
  }

  addEvent(event) {
    const caseID = this.getCaseID();
    console.log(event);
    if (!caseID) {
      this.addEventToInput(event);
    } else {
      switch (event.type) {
        case 'call':
          Api.post(`/api/sales/cases/${caseID}/calls`, event.callID);
          break;
        case 'mail':
          Api.post(`/api/sales/cases/${caseID}/mails`, event.mailID);
          break;
        default:
          break;
      }
    }
  }

  moveCallEvent(callID) {
    const caseID = this.getCaseID();
    if (!caseID) {
      return;
    }

    pickCase(
      async (selected) => {
        await Api.post(`/api/sales/cases/${selected.ID}/calls`, callID);
        this.removeCallEvent(callID);
      },
      {
        title: tr('label.move_to_another_ticket', 'Ticketing'),
        filters: {
          'ID:neq': this.getCaseID(),
        },
      }
    );
  }

  async removeCallEvent(callID) {
    const caseID = this.getCaseID();

    if (caseID) {
      await Api.delete(`/api/sales/cases/${caseID}/calls/${callID}`);
    }

    offerEventBus.emit('call/remove', callID);
  }

  moveChatEvent(chatID) {
    const caseID = this.getCaseID();
    if (!caseID) {
      return;
    }

    pickCase(
      async (selected) => {
        await Api.post(`/api/sales/cases/${selected.ID}/customerChats`, chatID);
        this.removeChatEvent(chatID);
      },
      {
        title: tr('label.move_to_another_ticket', 'Ticketing'),
        filters: {
          'ID:neq': this.getCaseID(),
        },
      }
    );
  }

  async removeChatEvent(chatID) {
    const caseID = this.getCaseID();

    if (caseID) {
      await Api.delete(`/api/sales/cases/${caseID}/customerChats/${chatID}`);
    }

    offerEventBus.emit('chat/remove', chatID);
  }

  addEventToInput(event) {
    this.setEventsInput((prev) => ({
      ...prev,
      $add: [...prev.$add, event],
    }));
  }

  setEventsInput(updater) {
    const $container = $('#events');
    let $input = $container.find('input[name=eventChanges]');
    if (!$input.length) {
      $input = $('<input/>', {
        type: 'hidden',
        name: 'eventChanges',
      });
      $container.append($input);
    }

    const inputVal = $input.val();
    const newVal = inputVal.length
      ? updater(JSON.parse(inputVal))
      : updater({ $add: [], $remove: [] });

    $input.val(JSON.stringify(newVal));
  }

  getCaseID() {
    return this.caseInfo?.ID ?? null;
  }
}
