import { ReactElement, useReducer } from 'react';
import { Portal } from '@chakra-ui/react';
import { createPortal } from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { useGlobalEvent } from 'BootQuery/Assets/js/global-event-bus';

import { HistorySyncer } from '../HistorySyncer';
import { PageContent } from '../PageContent';
import {
  ReactWidgetDefinition,
  useReactWidgets,
} from './react-widget-provider';
import { WidgetErrorFallback } from './WidgetErrorFallback';

export const WidgetsRoot = () => {
  const widgets = useReactWidgets();

  return (
    <>
      <HistorySyncer />
      <ReactWidget
        key="$pageContent"
        component={PageContent}
        target="#react-root-container"
      />
      {widgets.map((widget) => (
        <ReactWidget key={widget.name} {...widget} />
      ))}
    </>
  );
};

const ReactWidget = ({
  component: Component,
  target,
  condition,
}: ReactWidgetDefinition): ReactElement | null => {
  const forceRerender = useForceRerender();

  // After new "legacy-mode" elements are rendered, force a rerender
  // To re-search for target element if it was missing
  useGlobalEvent('activateElements', forceRerender);

  if (condition && !condition()) {
    return null;
  }

  if (target === '$portal') {
    return (
      <Portal>
        <ErrorBoundary FallbackComponent={WidgetErrorFallback}>
          <Component />
        </ErrorBoundary>
      </Portal>
    );
  }

  const targetElement =
    typeof target === 'function' ? target() : document.querySelector(target);
  if (!targetElement) {
    return null;
  }

  return createPortal(
    <ErrorBoundary FallbackComponent={WidgetErrorFallback}>
      <Component />
    </ErrorBoundary>,
    targetElement
  );
};

function useForceRerender() {
  const [, rerender] = useReducer((state) => state + 1, 0);

  return rerender;
}
