import Module from 'BootQuery/Assets/js/module';
import { find } from 'lodash-es';
import { Api } from 'BootQuery/Assets/js/api';
import { Settings } from '../components/Settings/Settings';
import { ReactRoutes } from './react-routes';

export default class Phonebook extends Module {
  init(data) {
    super.init(data);
  }

  get provides() {
    return {
      numberContactInfo: {
        async getContactInfo(phoneNumber) {
          const { data: contacts } = await Api.get(
            `/api/phonebook/contactForNumber/${phoneNumber}`
          );

          const contact = contacts[0];
          if (!contact) {
            return null;
          }

          switch (contact.type) {
            case 'user':
              return {
                icon: 'fas fa-user',
                link: contact.person ? `/phonebook/people/${contact.person.ID}` : null,
                displayName: contact.person?.name ?? contact.name,
              };
            case 'person':
              return {
                icon: 'fas fa-id-card',
                link: `/phonebook/people/${contact.ID}`,
                displayName: contact.name,
              };
            case 'company':
              return {
                icon: 'fas fa-building',
                link: `/phonebook/companies/${contact.ID}`,
                displayName: contact.name,
              };
            case 'companyLocation':
              return {
                icon: 'fas fa-user',
                link: `/phonebook/companies/${contact.company.ID}`,
                displayName: `${contact.name} (${contact.company.name})`,
              };
            default:
              console.error('Unknown contact type', contact.type);
              return null;
          }
        },
        priority: 10,
      },
      reactWidgets: {
        phonebookSettings: {
          target: '#phonebook-settings-root',
          component: Settings,
        },
      },
      reactRoutes: {
        ReactRoutes,
      },
    };
  }

  static matchReactRoute = '/phonebook/';

  activateElements(target, data) {
    target
      .findElement('#person-company-switcher')
      .ev('shown.bs.tab.phonebook', (e) => {
        const actionInput = target.findElement('input[name="action"]');
        const href = $(e.target).attr('href');
        if (href === '#add-person') {
          actionInput.val('insertPerson');
        } else if (href === '#add-company') {
          actionInput.val('insertCompany');
        }
      });
  }

  findPhonebookTab(data) {
    return find(data.result.tabs, {
      key: 'phonebook',
    });
  }
}
