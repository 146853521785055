import mitt from 'mitt';

import { createUseMitt } from 'BootQuery/Assets/js/use-mitt';

import {
  CaseSelectHandler,
  SelectCaseOptions,
} from '../components/Cases/Form/Events/CasePickerModal';

export async function pickCase(
  onSelect: CaseSelectHandler,
  options?: SelectCaseOptions
): Promise<void> {
  // Summon modal implemented in CasePickerConnector
  pickCaseEventBus.emit('pickCase', { onSelect, options });
}

export type PickCaseEventBus = {
  pickCase: {
    onSelect: CaseSelectHandler;
    options?: SelectCaseOptions;
  };
};

export const pickCaseEventBus = mitt<PickCaseEventBus>();
export const usePickCaseEvent = createUseMitt(pickCaseEventBus);
