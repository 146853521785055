import Module from 'BootQuery/Assets/js/module';

import { Settings } from '../components/Settings/Settings';
import { ReactRoutes } from './react-routes';

export default class Events extends Module {
  init(data) {
    super.init(data);
  }

  get provides() {
    return {
      reactRoutes: {
        ReactRoutes,
      },
      reactWidgets: {
        productSettings: {
          target: '#product-settings-root',
          component: Settings,
        },
      },
    };
  }

  static matchReactRoute = '/products/';
}
