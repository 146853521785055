import { purgeReact } from './render-react';
import { routeEvents } from './route-events';

export function activateReact() {
  const url = `${window.location.pathname}${window.location.search}`;
  routeEvents.emit('routeChange', url);
}

export function renderReactRoute() {
  let target = $('#react-root-container');
  if (target.length === 0) {
    const renderTo = $(window.targetElement);
    target = $('<div/>', { id: 'react-root-container' });
    purgeReact(renderTo[0]);

    // Purge vue
    renderTo.find('[data-vue-boundary]').each((_i, el) => {
      const vueInstance = el.__vue__; // eslint-disable-line no-underscore-dangle
      if (vueInstance) {
        vueInstance.$destroy();
      }
    });

    $(window.targetElement).html(target);
  }

  activateReact();
  $(document).trigger('activateElements', [
    $(window.targetElement),
    window.Bootstrap,
  ]);
}
