import Module from 'BootQuery/Assets/js/module';

import { eventTypeProviders } from '../components/event-type-providers';
import { Settings } from '../components/Settings';
import { ReactRoutes } from './react-routes';

export default class Events extends Module {
  init(data) {
    super.init(data);
  }

  get provides() {
    return {
      reactRoutes: {
        ReactRoutes,
      },
      reactWidgets: {
        eventsSettings: {
          component: Settings,
          target: '#events-settings-root',
        },
      },
      eventTypes: eventTypeProviders,
    };
  }

  static matchReactRoute = /^[/](events|calendar)[/]/;
}
