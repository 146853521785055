import mitt from 'mitt';

import { createUseMitt } from 'BootQuery/Assets/js/use-mitt';

import {
  SelectTicketOptions,
  TicketSelectHandler,
} from '../components/TicketPickerModal';

export async function pickTicket(
  onSelect: TicketSelectHandler,
  options?: SelectTicketOptions
): Promise<void> {
  // Summon modal implemented in TicketPickerConnector
  pickTicketEventBus.emit('pickTicket', { onSelect, options });
}

export type PickTicketEventBus = {
  pickTicket: {
    onSelect: TicketSelectHandler;
    options?: SelectTicketOptions;
  };
};

export const pickTicketEventBus = mitt<PickTicketEventBus>();
export const usePickTicketEvent = createUseMitt(pickTicketEventBus);
