import { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { LoadingPage } from '@bq/components/LoadingPage';

import { getCustomEventTypes } from '../api';
import { CustomEventTypesEdit } from './CustomEventTypesEdit';
import { EventTypeSettingsProvider } from './EventTypeSettingsContext';

export const Settings = (): ReactElement => {
  const { data: customEventTypes } = useSettingsEventTypes();

  return (
    <Box>
      {customEventTypes ? (
        <EventTypeSettingsProvider initial={customEventTypes}>
          <CustomEventTypesEdit />
        </EventTypeSettingsProvider>
      ) : (
        <LoadingPage />
      )}
    </Box>
  );
};

function useSettingsEventTypes() {
  return useQuery({
    queryKey: ['Events.settings.customEventTypes'],
    queryFn: getCustomEventTypes,
  });
}
