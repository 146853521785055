import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { useFormState } from '@bq/components/form-state';
import { loadValues } from '@bq/components/FormEditor';
import { useUniqueID } from '@bq/components/use-unique-id';
import { CustomData } from 'BootQuery/Assets/js/globalTypes';

import { FormSalesCase } from '../../schemas';
import { CaseCallCatcher } from './CaseCallCatcher';
import {
  CaseFormContext as CaseFormContextI,
  CaseFormWrapperProps,
  UseCaseFormProviderReturn,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CaseFormContext = createContext<CaseFormContextI | null>(null);

export const CaseFormWrapper = ({
  children,
  mode,
  type,
  customFields,
  data,
  campaignID,
  caseID,
}: PropsWithChildren<CaseFormWrapperProps>) => {
  const [saveID, resetSaveID] = useUniqueID();

  const customFieldDefaults = loadValues(customFields, data?.data ?? {});

  const methods = useForm<FormSalesCase>({
    defaultValues: data
      ? {
          tags: [],
          caseOffers: {
            add: [],
            del: [],
            upd: {},
            defaults: data.caseOffers?.defaults ?? [],
          },
          caseTickets: {
            add: [],
            del: [],
            upd: {},
            defaults: data.caseOffers?.defaults ?? [],
          },
          reminders: {
            add: [],
            del: [],
            upd: {},
            defaults: data.reminders?.defaults ?? [],
          },
          ...data,
          data: customFieldDefaults as CustomData,
        }
      : {
          tags: [],
          caseOffers: {
            add: [],
            del: [],
            upd: {},
            defaults: [],
          },
          caseTickets: {
            add: [],
            del: [],
            upd: {},
            defaults: [],
          },
          reminders: {
            add: [],
            del: [],
            upd: {},
            defaults: [],
          },
        },
  });
  const [formState, setFormState] = useFormState();

  return (
    <CaseFormContext.Provider
      value={{
        formState,
        setFormState,
        mode,
        customFields,
        type,
        campaignID,
        saveID,
        resetSaveID,
        caseID,
      }}
    >
      <CaseCallCatcher caseID={caseID}>
        <FormProvider {...methods}>{children}</FormProvider>
      </CaseCallCatcher>
    </CaseFormContext.Provider>
  );
};

export const useCaseFormProvider = (): UseCaseFormProviderReturn => {
  const methods = useFormContext<FormSalesCase>();
  const ctx = useContext(CaseFormContext);
  if (!ctx) {
    throw Error('Missing CaseFormContext');
  }

  return useMemo(() => ({ methods, ctx }), [ctx, methods]);
};
