import { ReactElement } from 'react';
import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/react';
import { FallbackProps } from 'react-error-boundary';

export const WidgetErrorFallback = ({ error }: FallbackProps): ReactElement => {
  return (
    <Alert status="error" py="1">
      <AlertIcon />
      <AlertTitle>
        {error.name}: {error.message}
      </AlertTitle>
    </Alert>
  );
};
