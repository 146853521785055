import Module from 'BootQuery/Assets/js/module';
import { find } from 'lodash-es';
import { activateElements, getTemplate, getFormData } from 'BootQuery/Assets/js/BootQuery';
import {
  popoverForTrigger,
} from 'app/assets/js/util';
import Vue from 'BootQuery/Assets/js/vue';
import * as Api from 'BootQuery/Assets/js/apiRequest';
import { purgeReact } from 'BootQuery/Assets/js/render-react';
import { uniqid, parseURL } from 'app/assets/js/tsutil';
import qs from 'qs';
import getRouter from './router';
import MailsComponent from '../components/Mails.vue';
import SignatureEditor from './signatureEditor';
import { mailEventProviders } from '../components/EventTypes';
import { TemplateSettings } from '../components/TemplateSettings';

function replaceUriParams(url, paramsObj) {
  const parsed = parseURL(url);

  const query = { ...parsed.paramsObj, ...paramsObj };

  const queryStr = qs.stringify(query);
  return queryStr ? `${parsed.pathname}?${queryStr}` : parsed.pathname;
}

/**
 *  Get the base username, if format is that of shared mailbox.
 *  Eg. if username is "base.user@domain.tld/mailbox@domain.tld",
 *  this will return "base.user@domain.tld"
 */
function getBaseUsername(username) {
  return username.replace(/(.+)[[/\\]].+/g, '$1', 'g');
}

function getMailsTab() {
  return find(window.Bootstrap.result.tabs, { key: 'mails' });
}

export default class Mails extends Module {
  get provides() {
    return {
      eventTypes: mailEventProviders,
      reactWidgets: {
        mailTemplateSettings: {
          target: '#mail-settings-root',
          component: TemplateSettings,
        },
      },
    };
  }

  init(_data) {
    super.init();
    $(document).ev('renderController.mails', () => {
      if (this.component) {
        this.component.$destroy();
        this.component = null;
      }
    });
    this.getSignatureTemplateData();
    this.getAvailableAccounts();
    this.getTemplates();

    this.socketEvents.subscribeWebSocket('mails/messageSent', (info) => {
      this.emit('messageSent', info);
    });

    $(window.document).ev('settingsChanged.mails', (_e, changed) => {
      if (changed.Mails && changed.Mails.changed) {
        this.onSettingsChange(changed.Mails.changed);
      }
    });
  }

  onSettingsChange(changes) {
    // Update cached display settings if present
    if (changes.displaySettings && this.displaySettings) {
      this.displaySettings = {
        ...this.displaySettings,
        ...changes.displaySettings,
      };
    }
  }

  async getSignatureTemplateData() {
    if (!this.signatureTemplateData) {
      this.signatureTemplateData = await Api.get('/api/mails/signatureTemplateData');
    }
    return this.signatureTemplateData;
  }

  async getTemplates() {
    if (!this.templates) {
      this.templates = await Api.get('/api/mails/templates');
    }
    return this.templates;
  }

  /**
   * Get a list of mail accounts
   *
   * @param {object} conditions - specify to return deleted or dissallowed accounts
   * @param {boolean} [conditions.withDeleted=false] - Also return deleted accounts?
   * @param {boolean} [conditions.withDisallowed=false] - Also return disallowed accounts?
   */
  async getAvailableAccounts(conditions = {}) {
    if (!this.availableAccounts) {
      this.availableAccounts = await Api.get('/api/mails/availableAccounts');
    }

    return this.availableAccounts.filter((acc) => {
      if (!conditions.withDeleted && acc.deleted) {
        return false;
      }

      if (!conditions.withDisallowed && !acc.available) {
        return false;
      }

      return true;
    });
  }

  async getCanView() {
    if (!this.canView) {
      this.canView = await Api.get('/api/mails/canView');
    }
    return this.canView;
  }

  async getDisplaySettings() {
    if (!this.displaySettings) {
      this.displaySettings = await Api.get('/api/mails/displaySettings');
    }
    return this.displaySettings;
  }

  activateElements(target, _data) {
    $(document).ev('click', 'a[href^="mailto:"]:not(.noparse, [data-toggle], [download])', (e) => {
      const originalEv = e.originalEvent || e; // Unwrap jQuery-wrapped event
      if (originalEv.defaultPrevented) {
        return;
      }

      const $el = $(e.currentTarget);
      if ($el.data('mail-link-activated')) {
        return;
      }

      e.preventDefault();
      $el.data('mail-link-activated', true);
      const href = $el.attr('href');
      const mail = href.replace(/^mailto:/, '');
      this.activateMailLinkPopover(e.currentTarget, mail);
    });

    const $mailsContainer = target.findElement('#mails-container');
    if ($mailsContainer.length) {
      this.renderMailInterface($mailsContainer);
    }

    target.findElement('[data-edit-mail-account]').ev('click.mails', (e) => {
      e.preventDefault();
      const btn = $(e.currentTarget);
      const mailAccountListItem = btn.closest('.list-group-item');
      const accountID = btn.data('mailAccountId');
      const accountDataName = `mailAccounts[${accountID}]`;
      let accountData = null;
      console.log('Clicky', mailAccountListItem);
      console.log('Sel: ', `input[type="hidden"][name="${accountDataName}"]`);
      let mailAccountInfo = mailAccountListItem.findElement(
        `input[type="hidden"][name="${accountDataName}"]`,
      );
      if (mailAccountInfo.length) {
        console.log('Mail account info: ', mailAccountInfo);
        accountData = JSON.parse(mailAccountInfo.val());
      } else {
        // Find uses strict matching so accountID needs to be converted to int.
        // Not parsed to int before because the selector will sometimes be new1, new2, etc
        // for new mail accounts which never end up in data sent from server-side
        accountData = find(getMailsTab().data.accounts, {
          ID: parseInt(accountID, 10),
        });
      }
      this.editMailAccount(accountData, (saved) => {
        if (!mailAccountInfo.length) {
          mailAccountInfo = $('<input/>', {
            type: 'hidden',
            name: accountDataName,
          });
          mailAccountInfo.appendTo(mailAccountListItem);
        }
        mailAccountInfo.val(JSON.stringify(saved));
      });
    });

    target.findElement('[data-add-mail-account]').ev('click.mails', (e) => {
      e.preventDefault();
      this.editMailAccount({ isNewAccount: true }, async (saved) => {
        console.log('Saved new: ', saved);
        const accountID = this.nextNewAccountID(target);
        saved.ID = accountID;
        saved.isNewAccount = true;

        const accountDataName = `mailAccounts[${accountID}]`;
        const itemTemplate = await getTemplate('Mails.mailAccountListItem');
        const item = $.render(itemTemplate, saved);
        const dataInput = $('<input/>', {
          type: 'hidden',
          name: accountDataName,
          value: JSON.stringify(saved),
        });
        item.append(dataInput);
        target.findElement('.mail-accounts-list').append(item);
        this.activateElements(target);
      });
    });
  }

  async activateMailLinkPopover(target, address) {
    const $target = $(target);

    $target.off('click');
    $target.ev('click.mails', (ev) => {
      ev.preventDefault();
      $target.popover('toggle');
    });
    $target
      .ev('inserted.bs.popover', (e) => {
        popoverForTrigger(e.currentTarget).addClass('popover-opening');
      })
      .ev('shown.bs.popover', (e) => {
        const $popover = popoverForTrigger(e.currentTarget);
        $popover.removeClass('popover-opening');
        $popover.find('[data-dismiss=popover]').ev('click.popoverDismiss', (e) => {
          $(e.currentTarget)
            .closest('.popover')
            .popover('hide');
        });
      });

    const [contentTemplate, titleTemplate] = await Promise.all([
      getTemplate('Mails.mailAddressPopover'),
      getTemplate('Mails.mailAddressPopoverTitle'),
    ]);

    const hasMailAccount = !!this.availableAccounts;
    const renderData = { address, hasMailAccount };
    $target.popover({
      html: true,
      title: () => $.render(titleTemplate, renderData),
      content: () => $.render(contentTemplate, renderData),
    });
    $target.popover('show');
  }

  async renderMailInterface($mailsContainer) {
    const mailsContainer = $mailsContainer[0];
    if (mailsContainer.dataset.activated) {
      return; // Don't double-render
    }
    mailsContainer.dataset.activated = true;
    this.component = new Vue({
      el: mailsContainer,
      router: getRouter(),
      render: (h) => h(MailsComponent),
    });
  }

  renderMailsRoute() {
    purgeReact($(window.targetElement)[0]);
    const target = $('<div/>', { id: 'mails-container' });
    $(window.targetElement).html(target);
    this.renderMailInterface(target);
    $(document).trigger('activateElements', [$(window.targetElement), window.Bootstrap]);
  }

  async editMailAccount(data, onSave) {
    const template = await getTemplate('Mails.accountSettingsModal');
    const allAuthProviders = getMailsTab().data.authProviders ?? [];
    const authProviders = allAuthProviders
      .map((provider) => ({ ...provider, selected: provider.id === data.authMethod }));

    const settingsModal = $.render(template, {
      ...data,
      authProviders,
    });
    const signatureEditor = new SignatureEditor(data.signature || {}, {
      'agent.firstName': 'Ime',
      'agent.lastName': 'Prezime',
      'agent.companyPosition': 'Pozicija',
      'agent.companyDepartment': 'Odjel',
      'agent.companyLocation': 'Poslovnica',
      'agent.phoneNumbers.Mobilni posao': 'Broj (Mobilni posao)',
      'agent.phoneNumbers.Telefon posao': 'Broj (Telefon posao)',
      'agent.phoneNumbers.Mobilni privat': 'Broj (Mobilni privat)',
      'agent.phoneNumbers.Telefon privat': 'Broj (Telefon privat)',
      'agent.emails': 'E-mail',
    });
    signatureEditor.show(settingsModal.findElement('.signature-edit'));

    settingsModal
      .findElement('#mail-password-change-collapse')
      .ev('show.bs.collapse.mails', (e) => {
        $(e.currentTarget)
          .findElement('#newPassword')
          .prop('disabled', false);
        $(e.currentTarget)
          .findElement('[name="passwordChanged"]')
          .val('true');
      });
    settingsModal
      .findElement('#mail-password-change-collapse')
      .ev('hidden.bs.collapse.mails', (e) => {
        $(e.currentTarget)
          .findElement('#newPassword')
          .prop('disabled', true);
        $(e.currentTarget)
          .findElement('[name="passwordChanged"]')
          .val('false');
      });
    settingsModal.findElement('.save-btn').ev('click', (e) => {
      e.preventDefault();
      const saved = {

        incomingProto: data.incomingProto,
        ...getFormData(settingsModal),
      };
      saved.incomingPort = parseInt(saved.incomingPort, 10);
      saved.incomingSSL = saved.incomingSSL === 'true';
      saved.outgoingPort = parseInt(saved.outgoingPort, 10);
      saved.outgoingSSL = saved.outgoingSSL === 'true';
      saved.outgoingAuth = saved.outgoingAuth === 'true';
      saved.passwordChanged = saved.passwordChanged === 'true';
      saved.signature = signatureEditor.data();
      saved.showUserNameInFrom = saved.showUserNameInFrom === 'true';
      saved.useRawSignature = $('#signature-edit-html').hasClass('active');
      settingsModal.modal('hide');
      onSave(saved);
    });
    settingsModal.on('hidden.bs.modal', () => {
      settingsModal.modal('dispose');
      settingsModal.remove();
    });

    settingsModal.modal('show');
    activateElements(settingsModal);
    this.updateAuthMethod(settingsModal, data.oauthAccountID);
    settingsModal.findElement('#authMethod').ev('change.mails', () => {
      this.updateAuthMethod();
    });
  }

  updateAuthMethod(target = 'body', initialAccountID = null) {
    const $tgt = $(target);

    const authProvider = $tgt.findElement('#authMethod').val();
    const $accountPicker = $tgt.findElement('#oauthAccountID');
    const $providerLogin = $accountPicker.closest('.row');
    const $passwordLogin = $tgt.findElement('#password').closest('.row');

    if (authProvider) {
      const accounts = getMailsTab().data.oauthAccounts
        .filter((acc) => acc.provider === authProvider)
        .map((acc) => ({ id: acc.ID, text: acc.displayName, persist: true }));
      const options = [
        ...accounts,
        {
          id: '$authorise$',
          isNewOption: true,
          persist: true,
          text: 'Nova prijava...',
        },
      ];
      $accountPicker.pickle('options', options);
      if (initialAccountID) {
        $accountPicker.pickle('select', initialAccountID);
      }
      $accountPicker.ev('select.mails', (selected) => {
        if (selected.value === '$authorise$') {
          this.openOAuthLoginWindow(authProvider);
        }
      });
      $providerLogin.prop('hidden', false);
      $passwordLogin.prop('hidden', true);
    } else {
      $providerLogin.prop('hidden', true);
      $passwordLogin.prop('hidden', false);
    }
  }

  openOAuthLoginWindow(provider) {
    const state = uniqid();
    const username = $('#username').val() ?? $('#email').val();
    const uri = replaceUriParams(`/mailAccounts/authMethods/${provider}/authorise`, {
      state,
      username: username ? getBaseUsername(username) : undefined,
      displayName: `${username} ${state}`,
    });
    const authFrame = window.open(uri, '_blank', 'width=500, height=600');
    window.addEventListener('storage', (ev) => {
      if (ev.key !== 'oauthComplete' || !ev.newValue) {
        return;
      }

      const result = JSON.parse(ev.newValue);

      if (result.state === state) {
        $('#oauthAccountID').pickle('option', result.oauthAccountID, {
          id: result.oauthAccountID,
          text: result.displayName,
          persist: true,
        });
        $('#oauthAccountID').pickle('select', result.oauthAccountID);
        authFrame.close();
      }
    });
  }

  nextNewAccountID(target) {
    let maxID = 0;
    target.find('input[name^="mailAccounts"]').each((_index, form) => {
      const idstr = $(form)
        .attr('name')
        .match(/mailAccounts\[new(\d+)\]/)[1];
      if (idstr) {
        maxID = Math.max(parseInt(idstr, 10), maxID);
      }
    });

    return `new${maxID + 1}`;
  }

  handleRoute(route) {
    if (route.startsWith('/mails')) {
      $(document).trigger('renderController', [window.targetElement, window.Bootstrap]);
      this.renderMailsRoute();
      return;
    }
    throw new Error(`Don't know how to handle route: '${route}'`);
  }

  static canHandleRoute(route) {
    if (route.startsWith('/mails')) {
      return true;
    }
    return false;
  }
}
